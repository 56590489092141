import { QueryResponse } from '@grana/api/radar-admin'
import { AdminClient } from '@grana/api/radar-admin.client'
import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { Layouts } from 'react-grid-layout'
import { Action } from 'redux'
import uuid from 'uuid'

import config from './config'
import { Duck } from './Duck'
import { mapCard, mapStatus, mapTable, QueryState, Status } from './queryDuck'
import { Thunk } from './store'

export type DashboardState = {
  queries: {
    [id: string]: QueryState
  }
  layouts: Layouts
  theme: Theme
}

export interface UpdateLayoutsAction extends Action<string> {
  payload: Layouts
}

interface UpdateQueryInputAction extends Action<string> {
  payload: { id: string; field: string; value: string | number }
}

interface ClearQueryInputAction extends Action<string> {}

interface TimedQueryResponse {
  response: QueryResponse
  timeElapsed: number
}

export interface LoadAction extends Action<string> {
  payload: Promise<TimedQueryResponse>
  meta: {
    queryId: string
  }
}

export interface UnloadAction extends Action<string> {
  payload: {
    queryId: string
  }
}

export interface LoadPendingAction extends Action<string> {
  meta: {
    queryId: string
  }
}

export interface AddAction extends Action<string> {
  payload: {
    id: string
    expression: string
    title?: string
  }
}

export interface DeleteAction extends Action<string> {
  payload: string
}

export interface LoadFulFilledAction extends Action<string> {
  payload: TimedQueryResponse
  meta: {
    queryId: string
  }
}

export interface ToggleAutoRefreshAction extends Action<string> {
  payload: {
    queryId: string
  }
}

export interface ChangeThemeAction extends Action<string> {
  payload: {
    theme: Theme
  }
}

export enum Theme {
  Light = 'light',
  Dark = 'dark'
}

type DashboardAction =
  | UpdateLayoutsAction
  | LoadFulFilledAction
  | AddAction
  | LoadPendingAction
  | DeleteAction
  | ToggleAutoRefreshAction
  | UnloadAction
  | ChangeThemeAction
  | UpdateQueryInputAction
  | ClearQueryInputAction

export class DashboardDuck extends Duck<DashboardState, DashboardAction> {
  public readonly UPDATE_LAYOUTS = this.type('UPDATE_LAYOUTS')
  public readonly LOAD = this.type('LOAD')
  public readonly LOAD_PENDING = this.type('LOAD_PENDING')
  public readonly LOAD_FULFILLED = this.type('LOAD_FULFILLED')
  public readonly UPDATE_QUERY_INPUT = this.type('UPDATE_QUERY_INPUT')
  public readonly CLEAR_QUERY_INPUT = this.type('CLEAR_QUERY_INPUT')
  public readonly UNLOAD = this.type('UNLOAD')
  public readonly ADD = this.type('ADD')
  public readonly DELETE = this.type('DELETE')
  public readonly TOGGLE_AUTO_REFRESH = this.type('TOGGLE_AUTO_REFRESH')
  public readonly CHANGE_THEME = this.type('CHANGE_THEME')

  public toggleAutoRefresh(queryId: string): ToggleAutoRefreshAction {
    return {
      type: this.TOGGLE_AUTO_REFRESH,
      payload: { queryId }
    }
  }

  public updateLayouts(layouts: Layouts): UpdateLayoutsAction {
    return {
      type: this.UPDATE_LAYOUTS,
      payload: layouts
    }
  }

  public updateQueryInput(
    id: string,
    field: string,
    value: string | number
  ): UpdateQueryInputAction {
    return {
      type: this.UPDATE_QUERY_INPUT,
      payload: { id, field, value }
    }
  }

  public clearQueryInput(): ClearQueryInputAction {
    return { type: this.CLEAR_QUERY_INPUT }
  }

  public delete(queryId: string): DeleteAction {
    return {
      type: this.DELETE,
      payload: queryId
    }
  }

  public add(expression: string, title?: string): Thunk<AddAction> {
    return dispatch => {
      const id = uuid.v4()
      dispatch({
        type: this.ADD,
        payload: { id, expression, title }
      })
      dispatch(this.load(id))
    }
  }

  public loadAll(): Thunk<LoadAction | UnloadAction | ClearQueryInputAction> {
    return (dispatch, getState) => {
      dispatch(this.clearQueryInput())
      Object.entries(getState().dashboard.queries).forEach(([id, query]) => {
        dispatch(query.autoRefresh ? this.load(id) : this.unload(id))
      })
    }
  }

  public unload(queryId: string): Thunk<UnloadAction> {
    return dispatch => {
      dispatch({
        type: this.UNLOAD,
        payload: { queryId }
      })
    }
  }

  public load(queryId: string): Thunk<LoadAction> {
    return (dispatch, getState) => {
      const state = getState()
      const startTime = new Date().getTime()
      dispatch({
        type: this.LOAD,
        meta: { queryId },
        payload: (async () => {
          const admin = new AdminClient(
            new GrpcWebFetchTransport({
              baseUrl: config.grpcUrl,
              format: 'binary',
              meta: state.authentication.headers
            })
          )
          const { response } = await admin.query({
            query: state.dashboard.queries[queryId].expression,
            userId: state.userSelector.user?.id || ''
          })
          return {
            response,
            timeElapsed: new Date().getTime() - startTime
          }
        })()
      })
    }
  }

  public changeTheme(theme: Theme): ChangeThemeAction {
    return {
      type: this.CHANGE_THEME,
      payload: { theme }
    }
  }

  public reducer(state: DashboardState = this.initialState(), action: DashboardAction) {
    switch (action.type) {
      case this.DELETE:
        const deletePayload = (action as DeleteAction).payload
        const { [deletePayload]: deletedQuery, ...otherQueries } = state.queries
        return {
          ...state,
          queries: otherQueries
        }
      case this.TOGGLE_AUTO_REFRESH:
        const { queryId } = (action as ToggleAutoRefreshAction).payload
        return {
          ...state,
          queries: {
            ...state.queries,
            [queryId]: {
              ...state.queries[queryId],
              autoRefresh: !!!state.queries[queryId].autoRefresh
            }
          }
        }
      case this.UPDATE_LAYOUTS:
        return { ...state, layouts: (action as UpdateLayoutsAction).payload }
      case this.LOAD_PENDING:
        const loadPendingQueryId = (action as LoadPendingAction).meta.queryId
        return {
          ...state,
          queries: {
            ...state.queries,
            [loadPendingQueryId]: {
              ...state.queries[loadPendingQueryId],
              status: Status.Loading
            }
          }
        }
      case this.UNLOAD:
        const unloadPayload = (action as UnloadAction).payload
        return {
          ...state,
          queries: {
            ...state.queries,
            [unloadPayload.queryId]: {
              ...state.queries[unloadPayload.queryId],
              status: Status.NotLoaded
            }
          }
        }
      case this.LOAD_FULFILLED:
        const { meta: loadFulfilledMeta, payload: loadFulfilledPayload } =
          action as LoadFulFilledAction
        return {
          ...state,
          queries: {
            ...state.queries,
            [loadFulfilledMeta.queryId]: {
              ...state.queries[loadFulfilledMeta.queryId],
              text:
                (loadFulfilledPayload.response.content.oneofKind === 'text' &&
                  loadFulfilledPayload.response.content.text) ||
                undefined,
              table:
                (loadFulfilledPayload.response.content.oneofKind === 'table' &&
                  mapTable(loadFulfilledPayload.response.content.table)) ||
                undefined,
              card:
                (loadFulfilledPayload.response.content.oneofKind === 'card' &&
                  mapCard(loadFulfilledPayload.response.content.card)) ||
                undefined,
              status: mapStatus(loadFulfilledPayload.response),
              autoRefresh:
                loadFulfilledPayload.timeElapsed > 30000
                  ? false
                  : state.queries[loadFulfilledMeta.queryId].autoRefresh
            }
          }
        }

      case this.UPDATE_QUERY_INPUT:
        const { id, field, value } = (action as UpdateQueryInputAction).payload
        const query = state.queries[id]
        const input = query?.input ?? {}
        return {
          ...state,
          queries: {
            ...state.queries,
            [id]: { ...query, input: { ...input, [field]: value } }
          }
        }

      case this.CLEAR_QUERY_INPUT:
        return {
          ...state,
          queries: Object.fromEntries(
            Object.entries(state.queries).map(([id, state]) => [id, { ...state, input: undefined }])
          )
        }

      case this.ADD:
        const { payload } = action as AddAction
        return {
          ...state,
          layouts: {
            lg: [...state.layouts.lg, { i: payload.id, x: 0, y: 0, w: 3, h: 2 }],
            md: [...state.layouts.md, { i: payload.id, x: 0, y: 0, w: 3, h: 2 }],
            sm: [...state.layouts.sm, { i: payload.id, x: 0, y: 0, w: 3, h: 2 }],
            xs: [...state.layouts.xs, { i: payload.id, x: 0, y: 0, w: 3, h: 2 }],
            xxs: [...state.layouts.xxs, { i: payload.id, x: 0, y: 0, w: 3, h: 2 }]
          },
          queries: {
            ...state.queries,
            [payload.id]: {
              expression: payload.expression,
              status: Status.NotLoaded,
              autoRefresh: true,
              title: payload.title
            }
          }
        }
      case this.CHANGE_THEME: {
        const { payload } = action as ChangeThemeAction
        return {
          ...state,
          theme: payload.theme
        }
      }

      default:
        return state
    }
  }

  protected initialState(): DashboardState {
    return {
      queries: {},
      layouts: {
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: []
      },
      theme: Theme.Light
    }
  }
  protected namespace(): string {
    return 'radar'
  }
  protected store(): string {
    return 'dashboard'
  }
}

export const dashboardDuck = new DashboardDuck()
