interface Config {
  env: string
  grpcUrl: string
}

const config: Config = {
  env: process.env.REACT_APP_ENV || 'development',
  grpcUrl: process.env.REACT_APP_GRPC_URL || 'http://localhost:8080'
}

export default config
