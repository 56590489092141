import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import React, { FunctionComponent } from 'react'
import { GoogleLogin as OldGoogleLogin } from 'react-google-login'
import { useDispatch, useSelector } from 'react-redux'
import { authenticationDuck } from './authenticationDuck'
import config from './config'
import { ApplicationState } from './store'

const authenticatedSelector = (state: ApplicationState) => state.authentication.authenticated
function selectClientId() {
  if (config.env === 'staging') {
    return '762140216350-f1rg948gbucqa7r5gjad8onfk2nagchh.apps.googleusercontent.com'
  }

  return '348334539926-vfpncpg1rr1iiq5dtp0qv1f6v6pup55f.apps.googleusercontent.com'
}

export const Authentication: FunctionComponent = ({ children }) => {
  const storeAuthenticated = useSelector(authenticatedSelector)
  const authenticated = storeAuthenticated

  if (config.env === 'staging') {
    return <GoogleAuthentication>{children}</GoogleAuthentication>
  }

  // Change for new authentication flow once refresh token feature is ready
  return <OldGoogleAuthentication>{children}</OldGoogleAuthentication>
}

export const GoogleAuthentication: FunctionComponent = ({ children }) => {
  const storeAuthenticated = useSelector(authenticatedSelector)
  const authenticated = storeAuthenticated
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <GoogleOAuthProvider clientId={selectClientId()}>
        {!authenticated && (
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexWrap: 'wrap',
              zoom: 1.5,
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#aaa'
            }}
          >
            <GoogleLogin
              onSuccess={credentialResponse =>
                dispatch(authenticationDuck.newLogin(credentialResponse))
              }
              onError={() => console.info('Login Failed')}
              hosted_domain="grana.capital"
              useOneTap
              auto_select
              cancel_on_tap_outside={false}
            />
          </div>
        )}
        {authenticated && children}
      </GoogleOAuthProvider>
    </React.Fragment>
  )
}

// DEPRECATED: Use GoogleAuthentication instead. Need to implement refresh token logic.
export const OldGoogleAuthentication: FunctionComponent = ({ children }) => {
  const storeAuthenticated = useSelector(authenticatedSelector)
  const isDev = process.env.NODE_ENV === 'development'
  const authenticated = isDev || storeAuthenticated
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexWrap: 'wrap',
          zoom: 1.5,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#aaa',
          visibility: authenticated ? 'hidden' : 'visible'
        }}
      >
        <OldGoogleLogin
          clientId="348334539926-vfpncpg1rr1iiq5dtp0qv1f6v6pup55f.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={r => dispatch(authenticationDuck.login(r))}
          onFailure={e => console.info('erro', e)}
          cookiePolicy="single_host_origin"
          isSignedIn={true}
          hostedDomain="grana.capital"
        />
      </div>
      {authenticated && children}
    </React.Fragment>
  )
}
