import { QueryResponse, QueryResponse_Card, QueryResponse_Table } from '@grana/api/radar-admin'
import { AdminClient } from '@grana/api/radar-admin.client'
import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Action } from 'redux'

import config from './config'
import { Duck } from './Duck'
import { Thunk } from './store'

dayjs.extend(utc)

export enum Status {
  NotLoaded,
  Loading,
  TextLoaded,
  TableLoaded,
  Failed,
  CardLoaded
}

interface UnresolvedQueryAction extends Action<string> {
  payload: Promise<QueryResponse>
}

interface RejectedQueryAction extends Action<string> {
  payload: {
    stack: string
  }
}

interface FulfilledQueryAction extends Action<string> {
  payload: QueryResponse
}

interface UpdateExpressionAction extends Action<string> {
  payload: string
}

export type TableOptions = {
  title?: string
  pagination?: boolean
  perPage?: number
}

type Table = {
  options?: TableOptions
  columns: string[]
  data: (string | number)[][]
}

type CardOptions = {
  title?: string
}

type Card = {
  text: string
  options?: CardOptions
}

export type QueryAction =
  | UnresolvedQueryAction
  | FulfilledQueryAction
  | UpdateExpressionAction
  | RejectedQueryAction

export type InjectedInput = { [name: string]: string | number | null }

export type QueryState = {
  expression: string
  status: Status
  title?: string
  input?: InjectedInput
  card?: Card
  text?: string
  table?: Table
  error?: any
  autoRefresh: boolean
}

export function mapTable(table: QueryResponse_Table) {
  return {
    options: table.options,
    columns: table.columns,
    data: table.data.map(row =>
      row.cells.map(cell => {
        switch (cell.content.oneofKind) {
          case 'number':
            return cell.content.number
          case 'text':
            return cell.content.text
          case 'date':
            return dayjs
              .utc(new Date(Number(cell.content.date.seconds) * 1000))
              .format('DD/MM/YYYY')
          default:
            throw new Error(
              `QueryResponse.Table.Cell.ContentCase ${cell.content.oneofKind} is invalid!`
            )
        }
      })
    )
  }
}

export function mapCard(card: QueryResponse_Card) {
  return {
    text: card.text,
    options: card.options
  }
}

export function mapStatus(response: QueryResponse) {
  switch (response.content.oneofKind) {
    case 'table':
      return Status.TableLoaded
    case 'text':
      return Status.TextLoaded
    case 'card':
      return Status.CardLoaded
    default:
      throw new Error(`QueryResponse.ContentCase ${response.content.oneofKind} is invalid!`)
  }
}

export class QueryDuck extends Duck<QueryState, QueryAction> {
  public readonly QUERY = this.type('QUERY')
  public readonly QUERY_PENDING = this.type('QUERY_PENDING')
  public readonly QUERY_FULFILLED = this.type('QUERY_FULFILLED')
  public readonly QUERY_REJECTED = this.type('QUERY_REJECTED')
  public readonly UPDATE_EXPRESSION = this.type('UPDATE_EXPRESSION')

  public updateExpression(expression: string): UpdateExpressionAction {
    return {
      type: this.UPDATE_EXPRESSION,
      payload: expression
    }
  }

  public reducer(state: QueryState = this.initialState(), action: QueryAction) {
    switch (action.type) {
      case this.QUERY_PENDING:
        return {
          ...state,
          status: Status.Loading
        }
      case this.QUERY_FULFILLED:
        const fulfilledQueryPayload = (action as FulfilledQueryAction).payload
        return {
          ...state,
          text:
            (fulfilledQueryPayload.content.oneofKind === 'text' &&
              fulfilledQueryPayload.content.text) ||
            undefined,
          table:
            (fulfilledQueryPayload.content.oneofKind === 'table' &&
              mapTable(fulfilledQueryPayload.content.table)) ||
            undefined,
          card:
            (fulfilledQueryPayload.content.oneofKind === 'card' &&
              mapCard(fulfilledQueryPayload.content.card)) ||
            undefined,
          status: mapStatus(fulfilledQueryPayload)
        }
      case this.QUERY_REJECTED:
        const payload = (action as RejectedQueryAction).payload
        const message = String(payload.stack)
        return { ...state, error: message, status: Status.Failed }
      case this.UPDATE_EXPRESSION:
        return {
          ...state,
          expression: (action as UpdateExpressionAction).payload
        }
      default:
        return state
    }
  }

  public query(expression?: string): Thunk<QueryAction> {
    return (dispatch, getState) => {
      const state = getState()
      dispatch({
        type: this.QUERY,
        payload: (async () => {
          const admin = new AdminClient(
            new GrpcWebFetchTransport({
              baseUrl: config.grpcUrl,
              format: 'binary',
              meta: state.authentication.headers
            })
          )
          const { response } = await admin.query({
            query: expression ?? state.query.expression,
            userId: state.userSelector.user?.id || ''
          })
          return response
        })()
      })
    }
  }

  protected initialState() {
    return {
      expression: '',
      status: Status.NotLoaded,
      autoRefresh: true
    }
  }
  protected namespace(): string {
    return 'radar-admin'
  }
  protected store(): string {
    return 'query'
  }
}

export const queryDuck = new QueryDuck()
