import { Chip } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { DashboardDuck, dashboardDuck } from '../dashboardDuck'
import { DuckActions } from '../Duck'
import { ApplicationState } from '../store'
import { UserSelector } from './UserSelector'
import { UserSelectorDuck, userSelectorDuck, UsersState } from './userSelectorDuck'

interface Props {
  userSelectorActions: DuckActions<UserSelectorDuck>
  dashboardActions: DuckActions<DashboardDuck>
  userSelector: UsersState
}

export const UserSelectorContainer = connect(
  (store: ApplicationState) => ({
    userSelector: store.userSelector
  }),
  dispatch => ({
    userSelectorActions: bindActionCreators(userSelectorDuck.creators(), dispatch),
    dashboardActions: bindActionCreators(dashboardDuck.creators(), dispatch)
  })
)(
  class UserSelectorContainer extends Component<Props> {
    render() {
      const userSelector = this.props.userSelector

      return (
        <>
          {userSelector.user?.partnerId && (
            <Chip
              style={{ color: '#fff', borderColor: '#fff', marginRight: '10px' }}
              label={userSelector.user.partnerId}
              variant="outlined"
            />
          )}
          {userSelector.user?.id && (
            <Chip style={{ color: '#04773b', marginRight: '10px' }} label={userSelector.user.id} />
          )}
          <UserSelector
            options={userSelector.list}
            selected={userSelector.user}
            onInputChange={value => {
              this.props.userSelectorActions.search(value)
            }}
            onSelect={user => {
              this.props.userSelectorActions.select(user)
              this.props.dashboardActions.loadAll()
            }}
          />
        </>
      )
    }
  }
)
